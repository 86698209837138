import React from 'react'
import { Container, Image } from 'react-bootstrap'
import { HeroModel } from "./model"

const PartHeroBanner = () => {
  return (
    <Container>
      <Image src={HeroModel.image} alt="about-hero" className="generalHerobanner"/>
    </Container>
  )
}

export default PartHeroBanner