import React from "react"
import { Container, Row, Col, Image } from 'react-bootstrap'
import { ImgModel } from "./model"

const ParticiimgModule = () => {
    
    return (
      <div className="section_pad_st">
        <Container>
          <Row className="section_shadow_st margin_0">
            <Col className="col-sm-6 background_mask_blue pad_0" >
              <Image src={ImgModel.image} alt="computer" className="inno_computer_st1 w-100"/>
            </Col>
            <Col className="col-sm-6 pad_65" >
                <p className="main_title main_b_color">{ImgModel.title}</p>
                <p className="main_text main_b_color">{ImgModel.text}</p>
            </Col>
            
          </Row>
        </Container>
        </div>
    )
  }

export default ParticiimgModule
