import hero from "../../images/partici-hero.jpg"

import picon1 from "../../images/pcard_icon1.png"
import picon2 from "../../images/pcard_icon2.png"
import picon3 from "../../images/pcard_icon3.png"
import picon4 from "../../images/pcard_icon4.png"

import boximage from "../../images/Inno-computer.png"

export const HeroModel = {
    image: hero,
}
export const ParticititleModel = {
    title: "Seamless Communication",
    text: "Jus-Pharma accelerates testing and validation from study conception through clinical trial phase 3. Participants can easily access documents pertaining to a trial while permitting effortless electronic or voice signature on a secure platform. Executives can seamlessly communicate with study teams and clinicians for rapid response times and monitoring overall progress.",
}

export const FeatureModel = [
    {
        icon: picon1,
        subtitle: "Expert Guidance",
    },
    {
        icon: picon2,
        subtitle: "Data",
    },
    {
        icon: picon3,
        subtitle: "Results",
    },
    {
        icon: picon4,
        subtitle: "Transparency",
    },
]

export const ImgModel = {
    image: boximage,
    title: "Cut Through the Red Tape",
    text: "Clinical trials often prove intimidating and confusing. Our team helps streamline the protocols and process. Additionally, this platform makes communication with the research team quicker and easier. Our team of experts ensure you are cared for and provide you the tools to remain fully informed throughout each clinical trial. Furthermore, we better connect participants with the right studies. Our platform allows you to discover, participate, and manage multiple clinical trials simultaneously. Jus-Pharma will ensure you have all the best options available to you and your loved ones.",
}