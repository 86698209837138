import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

import ParticiImage from "../components/participants/particiImage"
import ParticititleModule from "../components/common/titletextModule"
// import ParticicheckModule from "../components/participants/particicheckModule"
import FeatureModule from "../components/common/featureModule"
import ParticiimgModule from "../components/participants/particiimgModule"
import CTAModule from "../components/common/ctaModule"

import { ParticititleModel } from "../components/participants/model"
import { FeatureModel } from "../components/participants/model"

const ParticipantsPage = () => (
  <Layout>
        <SEO title="Participants" />
        <Container className="pad_header">
            <h1 className="header_title">For Participants</h1>
        </Container>
        <ParticiImage />
        <ParticititleModule model = {ParticititleModel} />
        <FeatureModule model = {FeatureModel} />
        <ParticiimgModule />
        <CTAModule 
            title="Connect with a Jus-Pharma team member today!"
            css='background_e'
        />
  </Layout>
)

export default ParticipantsPage

