import React from "react"
import { Link } from "gatsby"


const GeneralJusButton = props => {
  return (
    <div className="pt_15">
        <Link to="/contact" className="lets_button_st lets_b_txt">{props.label}</Link>
        <Link to="/contact" className="lets_button_st lets_b_icon">{props.icon}</Link>
    </div>
  )
}

export default GeneralJusButton