import React from "react"
import { Container } from 'react-bootstrap'

const ParticititleModule = props => {
    return (
        <div className="section_pad_st1 max_width_850">
            <Container>
                <div className={props.css}>
                    <h4 className="main_title main_b_color text-center max_width_600">{props.model.title}</h4>
                    <p className="main_text main_b_color text-center">{props.model.text}</p>
                </div>
            </Container>
        </div>
    )
}

export default ParticititleModule

