import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import GenBtn from "../common/general-btn"

const CTAModule = props => {
    return (
        <div className={props.css}>
            <Container className="section_pad_st">
                <Row className="text-center margin_0">
                    <Col
                        md={{ span: 6, offset: 3 }}
                    >
                        <h4 className="main_title main_b_color margin_bot_35">{props.title}</h4>
                        <GenBtn label="Let's get Started" icon=">"/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CTAModule

