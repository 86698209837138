import React from "react"
import { Container, CardDeck, Card } from 'react-bootstrap'

const CardsModule = pros => {
    return (
        <div className="section_pad_st1 feature_card">
            <Container>
                
                <CardDeck className="">
                    {pros.model.map((item, i) => {
                        return (
                            <Card className="text-center jus_card_st" key={i}>
                                <Card.Img className="max_h_50" variant="top" src={item.icon} alt={item.subtitle}/>
                                <Card.Body>
                                    <Card.Title className="sub_title main_b_color">{item.subtitle}</Card.Title>
                                    <Card.Text className="main_text main_b_color">{item.subtext}</Card.Text>
                                </Card.Body>
                            </Card>
                          )
                    })}
                </CardDeck>
            </Container>
        </div>
    )
}

export default CardsModule

